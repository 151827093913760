import { ChangeDetectionStrategy, Component, Optional, ViewEncapsulation } from '@angular/core';
import { Auth0IdentityService } from '@ridango/auth';
import { finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tenant',
    templateUrl: './tenant.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'tenant',
})
export class TenantComponent {
    loading = false;

    constructor(@Optional() public identityService: Auth0IdentityService, private translate: TranslateService) {}

    switchTenant(tenantId) {
        this.loading = true;
        this.identityService
            .setTenant(tenantId)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(() => {
                window.location.reload();
            });
    }
}
