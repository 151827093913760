<ng-container *ngIf="identityService && (identityService.tenants$ | async)?.length > 1">
    <ng-container *ngIf="identityService.isAuthenticated$ | async">
        <ng-container *ngIf="identityService.currentTenant$ | async as currentTenant">
            <button class="-mr-4" [loading]="loading" mat-button [matMenuTriggerFor]="tenantActions">
                <div class="flex flex-row items-center">
                    <div class="flex flex-col text-center p-2">
                        <span class="leading-4">Tenant</span>
                        <span class="text-sm leading-4 text-gray-400">{{ currentTenant }}</span>
                    </div>
                </div>
            </button>
            <mat-menu [xPosition]="'before'" #tenantActions="matMenu">
                <div class="px-4 py-2">
                    <span class="flex flex-col leading-none"> {{ 'portal.login.switch_tenant' | translate }} </span>
                </div>
                <mat-divider></mat-divider>
                <ng-container *ngFor="let tenant of identityService.tenants$ | async">
                    <button mat-menu-item (click)="switchTenant(tenant.name)" [disabled]="tenant.name === currentTenant">
                        {{ tenant.name }}
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
    </ng-container>
</ng-container>
