import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RuiButtonModule } from '@ridango/ui/button';
import { TenantComponent } from './tenant.component';
import { TranslateModule } from '@ngx-translate/core';
import { compiler, httpLoader as loader, I18nModule } from '@ridango/i18n';

@NgModule({
    declarations: [TenantComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        RuiButtonModule,

        I18nModule,
        TranslateModule.forRoot({
            compiler,
            loader,
        }),
    ],
    exports: [TenantComponent],
})
export class TenantModule {}
